import Vue from 'vue';
import App from '@/App';
// api: https://github.com/vuejs/vue-router
import router from '@/router';
// api: https://github.com/vuejs/vuex
import store from '@/store';
// api: https://github.com/alfhen/vue-cookie
import VueCookie from 'vue-cookie';
// api: https://github.com/ElemeFE/element
import '@/element-ui';
// api: http://www.iconfont.cn/
import '@/icons';
import '@/element-ui-theme';
import '@/assets/scss/index.scss';
import '@fortawesome/fontawesome-free/css/all.css';
// api: https://github.com/axios/axios
import httpRequest from '@/utils/httpRequest';
// 引入echarts
import echarts from 'echarts';
import VueClipboard from 'vue-clipboard2';

import FooterItem from './components/FooterItem.vue';
import ListFooter from './components/ListFooter.vue';

import { VueJsonp } from 'vue-jsonp';

import {
  isAuth,
  treeDataTranslate,
  transOrg,
  transUser,
  transDict,
  openImg,
  openVideo,
  transDate,
} from '@/utils';
import cloneDeep from 'lodash/cloneDeep';
import ElDict from './components/el-dict';
import ElImg from './components/el-img';
import ElBanner from './components/el-banner';
import OsBanner from './components/os-banner';
import ElImgs from './components/el-imgs';
import Ueditor from './components/ueditor';
import ImageCropperModal from './components/image-cropper';

Vue.use(VueCookie);
Vue.use(ElDict);
Vue.use(ElImg);
Vue.use(ElBanner);
Vue.use(OsBanner);
Vue.use(ElImgs);
Vue.use(Ueditor);
Vue.use(VueClipboard);
Vue.use(ImageCropperModal);

Vue.use(VueJsonp);

Vue.component('FooterItem', FooterItem);
Vue.component('ListFooter', ListFooter);
Vue.component('ElDict', ElDict);
Vue.component('ElImg', ElImg);
Vue.component('ElBanner', ElBanner);
Vue.component('OsBanner', OsBanner);
Vue.component('ElImgs', ElImgs);
Vue.component('Ueditor', Ueditor);
Vue.component('ImageCropperModal', ImageCropperModal);

Vue.config.productionTip = false;

// 挂载全局
window.moment = require('moment');

// ajax请求方法
Vue.prototype.$http = httpRequest;
Vue.prototype.$echarts = echarts;
// 权限方法
Vue.prototype.isAuth = isAuth;
// 树形数据转换
Vue.prototype.treeDataTranslate = treeDataTranslate;
// 机构翻译
Vue.prototype.transOrg = transOrg;
// 用户翻译
Vue.prototype.transUser = transUser;
// 数据字典翻译
Vue.prototype.transDict = transDict;
// 预览图片
Vue.prototype.openImg = openImg;
// 预览视频
Vue.prototype.openVideo = openVideo;
// 转时间
Vue.prototype.transDate = transDate;

// 保存整站vuex本地储存初始状态
window.SITE_CONFIG.storeState = cloneDeep(store.state);

// 存token到cookie中
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');
if (token) {
  Vue.cookie.set('token', token);
}

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});
