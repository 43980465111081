<template>
  <el-dialog
    :visible.sync="visible"
    style="z-index: 1000"
    width="950px"
    title="选择图片"
    append-to-body
  >
    <el-form :inline="true" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-button type="primary" @click="uploadHandle()">
          上传轮播图/视频
        </el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-col v-for="data in dataList" :key="data.id" :span="4">
        <el-card
          style="width: 160px; height: 260px"
          class="shadowClass"
          shadow="hover"
        >
          <div class="chooseIcon" v-if="data.isChoose">
            <i class="el-icon-check"></i>
          </div>
          <el-image
            :key="data.imageUrl"
            style="max-height: 150px"
            :src="data.imageUrl"
            @click="openImg(data.imageUrl)"
          />
          <span>{{ data.name }}</span>
          <div style="padding: 14px">
            <el-button
              type="success"
              icon="el-icon-circle-check"
              circle
              @click="selectImg(data)"
            />
            <el-button
              v-if="isAuth('sys:oss:delete')"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteHandle(data.id)"
            />
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-pagination
      :current-page="pageIndex"
      :page-sizes="[12, 18, 24, 48]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
    />
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="choosePic">确定选择图片</el-button>
    </span>
    <!-- 弹窗, 上传文件 -->
    <upload v-if="uploadVisible" ref="upload" @refreshDataList="getDataList" />
  </el-dialog>
</template>

<script>
import Upload from '../../views/modules/os/upload-banner';

export default {
  name: 'OsBanner',
  componentName: 'OsBanner',
  components: {
    Upload,
  },
  data() {
    return {
      dataList: [],
      pageIndex: 1,
      pageSize: 100,
      totalPage: 0,
      visible: false,
      uploadVisible: false,
      choosePicList: [],
    };
  },
  methods: {
    init(row) {
      this.visible = true;
      this.choosePicList = row;
      this.getDataList();
    },
    // 上传文件
    uploadHandle() {
      this.uploadVisible = true;
      this.$nextTick(() => {
        this.$refs.upload.init();
      });
    },
    selectImg(row) {
      row.isChoose = !row.isChoose;
    },
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/os/zoneBanner/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.page.records?.length) {
            data.page.records.forEach((i) => {
              if (this.choosePicList.length > 0) {
                let chooseItem = this.choosePicList.filter(
                  (s) => s.id === i.id,
                );
                i.isChoose = chooseItem.length > 0;
              } else {
                i.isChoose = false;
              }
            });
          }
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$confirm(`确定对数据进行[删除]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/os/zoneBanner/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.choosePicList = this.choosePicList.filter(
                (i) => i.id !== id,
              );
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 选择图片
    choosePic() {
      this.choosePicList = this.dataList.filter((i) => i.isChoose);
      this.visible = false;
      this.$emit('success', this.choosePicList);
    },
  },
};
</script>
<style lang="scss">
.shadowClass {
  position: relative;
  .chooseIcon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
    width: 30px;
    height: 30px;
    background: linear-gradient(45deg, transparent 50%, #ff0000 50%);
    i {
      color: #fff;
      position: absolute;
      top: 4px;
      right: 3px;
      font-size: 12px;
    }
  }
}
</style>
